import React from "react";

// COMPONENTS
import Container from "../container/container";
import NavBar from "../navbar/navbar";

//CSS
import "../../assets/stylesheets/layout.scss";

// Markup
const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <NavBar />
      <Container>
        <h1>Here goes all the content</h1>
        <main>{children}</main>
      </Container>
    </React.Fragment>
  );
};

export default Layout;
