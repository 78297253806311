import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

//CSS

//Components
import Layout from "../components/layout/layout";

// Styles
let Placeholder = styled.div`
  background: none;
`;

// Markup
const IndexPage = () => {
  return (
    <Layout>
      <Placeholder>
        <h1>Sell creatively</h1>
        <p>My first page</p>
        <p>
          Check out my <Link to="/stripe">Stripe</Link>!
        </p>
        <p>
          Check out my <Link to="/data">data</Link>!
        </p>
        <p>
          Check out my <Link to="/calander">calander</Link>!
        </p>
        <p>
          Check out my <Link to="/posts">posts</Link>!
        </p>
        <p>
          <Link to="/login">Login</Link>!
        </p>
      </Placeholder>
    </Layout>
  );
};

export default IndexPage;
