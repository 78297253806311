import React from "react";

//CSS
import * as componentStyles from "../navbar/navbar.module.scss";

// Markup
const NavBar = ({ children }) => {
  return (
    <div className={componentStyles.navbar}>
      <div className={componentStyles.navbarContainer}>
        <h1>navbar</h1>
        {children}
      </div>
    </div>
  );
};

export default NavBar;
